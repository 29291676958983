import * as React from "react"
import { HeadFC } from "gatsby"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { gs } from "../components/styling/GlobalStyles"
import Seo from "../components/Seo"
import { Main } from "../components/styling/Wrapper"
import { HeadingFP } from "../components/styling/Heading"
import { navigationInput } from "../components/Header"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { BiWindowAlt, BiCompass, BiTrophy, BiSolidCat } from "react-icons/bi"
import { FakeBtn } from "../components/styling/Button"
import FrontPagePreview from "../components/styling/FrontPagePreview"
import FrontPageCredits from "../components/styling/FrontPageCredits"

// #region styling
const blink = keyframes`
  from, to {
    color: ${gs.color.underline(0.4)};
    border-color: ${gs.color.underline(0.4)};
  }  
  45%, 55%{
    color: ${gs.color.blue};
    border-color: ${gs.color.blue};
  }
`
const blinkAlt = keyframes`
  from, to {
    color: ${gs.color.altUnderline(0.4)};
    border-color: ${gs.color.altUnderline(0.4)};
  }  
  45%, 55%{
    color: ${gs.color.alt};
    border-color: ${gs.color.alt};
  }
`
const blinkWhite = keyframes`
  from, to {
    color: rgba(235,235,235, 0.6);    
  }  
  45%, 55%{
    color: rgba(235,235,235, 1);
  }
`
const Section = styled.section`
  margin: 0;
  @media all and (min-width: ${gs.size.screen.md}) {
    margin: 0 0 20px 0;
  }
`
const DivPress = styled.div`
  display: flex;
  width: 100%;
  margin: 1.5rem 0;
  justify-content: center;
  @media all and (min-width: ${gs.size.screen.sm}) {
    align-items: center;
    align-self: center;
    justify-self: start;
    margin: 2rem 0;
  }
  @media all and (min-width: ${gs.size.screen.md}) {
    /* justify-content: flex-start; */
    margin: 3rem 0;
  }
`
const SpanFakeBtn = styled(FakeBtn)`
  color: ${gs.color.underline()};
  animation: ${blink} 4.6s ease-out infinite;
  margin: 0 10px;
  &::before,
  &::after {
    border-color: ${gs.color.translucent};
    animation: ${blink} 4.6s ease-out infinite;
  }
  .icon-circle {
    .inner {
      fill: ${gs.color.translucent};
    }
  }
`
const SpanFakeBtnAlt = styled(FakeBtn)`
  animation: ${blinkAlt} 4.6s ease-out infinite;
  color: ${gs.color.altUnderline()};
  margin: 0 10px;
  &::before,
  &::after {
    border-color: ${gs.color.altTranslucent};
    animation: ${blinkAlt} 4.6s ease-out infinite;
  }
  .icon-circle {
    .inner {
      fill: ${gs.color.altTranslucent};
    }
  }
`
const SpanDefaultC = styled.span`
  color: ${gs.color.primary};
`
const SpanAltC = styled.span`
  color: ${gs.color.alt};
`
const SpanSlash = styled.span`
  margin: 0 0.3rem;
  color: ${gs.color.underline(0.3)};
`
const PInstruction = styled.p`
  display: none;
  justify-content: center;
  align-items: center;
  font-size: ${gs.size.font.lg};
  color: ${gs.color.white};
  animation: ${blinkWhite} 4.6s ease-out infinite;
  margin: 0;
  @media all and (min-width: ${gs.size.screen.md}) {
    display: flex;
    font-size: 2rem;
  }
`
const PInstructionM = styled.p`
  font-size: 1.2rem;
  color: ${gs.color.white};
  animation: ${blinkWhite} 4.6s ease-out infinite;
  margin: 0;
  text-align: center;
  @media all and (min-width: 400px) {
    font-size: 1.4rem;
  }
  @media all and (min-width: ${gs.size.screen.sm}) {
    font-size: 1.8rem;
  }
  @media all and (min-width: ${gs.size.screen.md}) {
    display: none;
  }
`
// const HeaderFP = styled(Header)`
//   #Logo-Homepage-Link {
//     display: none;
//   }
//   @media all and (min-width: ${gs.size.screen.md}) {
//     display: none;
//     /* #Logo-Homepage-Link {
//       display: inline-flex;
//     } */
//   }
// `
// const FooterFP = styled(Footer)`
//   @media all and (min-width: ${gs.size.screen.md}) {
//     margin: 0 auto -9rem auto;
//   }
// `
// #endregion

const IndexPage = () => {
  React.useEffect(() => {
    window.addEventListener("keydown", navigationInput)
    return () => window.removeEventListener("keydown", navigationInput)
  }, [])
  return (
    <>
      {/* <HeaderFP /> */}
      <Header />
      <Main id="Project-Selection" as="main">
        <Section>
          <HeadingFP
            name="violet.eris.b."
            subtitle={
              <>
                <SpanAltC>Indie Dev</SpanAltC>
                <SpanSlash>|</SpanSlash>
                <SpanDefaultC>UI/UX Designer</SpanDefaultC>
              </>
            }
          />
          <DivPress>
            <PInstructionM>
              {"< "} Select a project to continue {" >"}
            </PInstructionM>
            <PInstruction>
              {"< "}
              Press
              <SpanFakeBtnAlt>
                <span>W</span>
                <BiTrophy title="Trophy Icon" />
              </SpanFakeBtnAlt>
              <SpanFakeBtnAlt>
                <span>A</span>
                <BiCompass title="Compass Icon" />
              </SpanFakeBtnAlt>
              <SpanFakeBtn>
                <span>S</span>
                <BiWindowAlt title="Browser Window Icon" />
              </SpanFakeBtn>
              or
              <SpanFakeBtn>
                <span>D</span>
                <BiSolidCat title="Cat Icon" />
              </SpanFakeBtn>
              to continue {" >"}
            </PInstruction>
          </DivPress>
          {/* <ProjectList /> */}
          {/* <MiscPageDiv/> */}
        </Section>
        <FrontPagePreview />
        <FrontPageCredits />
      </Main>
      {/* <FooterFP /> */}
      <Footer />
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <Seo title="Select a Project To Continue" />
