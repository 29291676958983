import * as React from "react"
import styled from "@emotion/styled"
import { gs } from "./GlobalStyles"
import { HeadingSection } from "./Heading"
import { SectionWrapper, Node } from "../Sections"
import { Figcaption } from "../styling/BorderWrapper"
import extrasList from "../creditsList"

const H3 = styled.h3`
  display: flex;
  width: 100%;
  margin: 5px 0 0 0;
  font-size: 1.3rem;
  line-height: 1.2;
`
const PRole = styled.p`
  ${gs.font.mono};
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 0.9;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  padding: 0;
  margin: 0 0 2px 0;
  color: ${gs.color.alt};
`
const PDetail = styled.p`
  margin: 5px 0 0 0;
`

const DivWithColsList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  position: relative;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
  @media all and (min-width: 420px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: ${gs.size.screen.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media all and (min-width: ${gs.size.screen.lg}) {
    gap: 20px;
  }
`

const HeadingSectionSm = styled(HeadingSection)`
  font-size: 2rem;
`

const FrontPageCredits = () => {
  return (
    <SectionWrapper
      heading={
        <HeadingSectionSm id="Additional-Credits">
          Additional Credits
        </HeadingSectionSm>
      }
    >
      <DivWithColsList>
        {extrasList.map((e, index) => (
          <Node.col
            key={index}
            body={
              <figure>
                {e.body}
                <Figcaption>
                  <H3>{e.heading}</H3>
                  <PRole>{e.role}</PRole>
                  <PDetail>{e.detail}</PDetail>
                </Figcaption>
              </figure>
            }
          />
        ))}
      </DivWithColsList>
    </SectionWrapper>
  )
}

export default FrontPageCredits
