import * as React from "react"
import styled from "@emotion/styled"
import { gs } from "./GlobalStyles"
import { SectionWrapper, Node } from "../Sections"
import BorderWrapper from "./BorderWrapper"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FakeBtn, FakeBtnAlt, hoverStyles } from "./Button"
import {
  BiWindowAlt,
  BiCompass,
  BiTrophy,
  BiSolidCat,
  BiSolidFlask,
} from "react-icons/bi"

const HiddenHeading = styled.h2`
  border: 0;
  clip: rect(1px 1px 1px 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`
const MultiCol = styled(Node.multiCol)`
  margin: 0 0 10px 0;
  gap: 10px;
  @media all and (min-width: ${gs.size.screen.sm}) {
    margin: 0 0 20px 0;
    flex-wrap: nowrap;
  }
`
const H3 = styled.h3`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  gap: 8px;
  font-size: ${gs.size.font.md};
  @media all and (min-width: ${gs.size.screen.md}) {
    font-size: ${gs.size.font.lg};
  }
`
const BorderWrapLinked = styled(BorderWrapper)`
  &::before,
  &::after,
  .borders::before,
  .borders::after,
  .caption::before {
    transition: all 250ms ease-in;
  }
`
const smallN = "5px"
const largeN = "10px"
const calcAdj = "15px"
const LinkPreview = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${gs.color.primary};
  height: min-content;
  ${FakeBtnAlt}, ${FakeBtn} {
    margin: -4px 0 0 0;
    padding: 0 2px 0 5px;
    .icon-mobile {
      font-size: ${gs.size.font.xl};
    }
    @media all and (min-width: ${gs.size.screen.md}) {
      padding: 5px 10px 3px 10px;
      margin: -2px 0 0 0;
    }
  }
  &:hover,
  &:focus-visible {
    color: ${gs.color.secondary};
    @media (prefers-reduced-motion: no-preference) {
      ${BorderWrapLinked} {
        &::before {
          top: ${smallN};
          left: ${largeN};
          width: calc(100% - ${calcAdj});
        }
        &::after {
          top: ${largeN};
          right: ${smallN};
          height: calc(100% - ${calcAdj});
        }

        .borders {
          &::before {
            bottom: ${smallN};
            right: ${largeN};
            width: calc(100% - ${calcAdj});
          }
          &::after {
            bottom: ${largeN};
            left: ${smallN};
            height: calc(100% - ${calcAdj});
          }
        }

        .caption {
          &::before {
            top: 3px;
            left: -2px;
            width: calc(100% + 4px);
          }
        }
      }
    }

    ${BorderWrapLinked} {
      &::before,
      &::after,
      .borders::before,
      .borders::after,
      .caption::before {
        background-color: ${gs.color.primary};
      }
    }

    .icon {
      color: ${gs.color.secondary};
      @media all and (min-width: ${gs.size.screen.md}) {
        ${hoverStyles};
        color: ${gs.color.secondary};
        background: none;
        box-shadow: initial;
        -webkit-box-shadow: initial;
        -moz-box-shadow: initial;
        &::before {
          border-color: ${gs.color.primary};
          width: calc(100% - 10px);
          border-width: 3px 0 0 3px;
        }
        &::after {
          border-color: ${gs.color.primary};
          width: calc(100% - 10px);
          border-width: 0 3px 3px 0;
        }
      }
    }
  }
`
const LinkPreviewAlt = styled(LinkPreview)`
  color: ${gs.color.alt};
  ${BorderWrapLinked} {
    &::before,
    &::after,
    .borders::before,
    .borders::after,
    .caption::before {
      background-color: ${gs.color.altUnderline()};
    }
  }
  &:hover,
  &:focus-visible {
    color: ${gs.color.altHover};
    ${BorderWrapLinked} {
      &::before,
      &::after,
      .borders::before,
      .borders::after,
      .caption::before {
        background-color: ${gs.color.alt};
      }
    }
    .icon {
      color: ${gs.color.altHover};
      @media all and (min-width: ${gs.size.screen.md}) {
        ${hoverStyles};
        color: ${gs.color.altHover};
        background: none;
        box-shadow: initial;
        -webkit-box-shadow: initial;
        -moz-box-shadow: initial;
        &::before {
          border-color: ${gs.color.alt};
          width: calc(100% - 10px);
          border-width: 3px 0 0 3px;
        }
        &::after {
          border-color: ${gs.color.alt};
          width: calc(100% - 10px);
          border-width: 0 3px 3px 0;
        }
      }
    }
  }
`
function FrontPagePreview() {
  return (
    <SectionWrapper heading={<HiddenHeading>Page Previews</HiddenHeading>}>
      <MultiCol
        col1={
          <LinkPreviewAlt
            to="/case-studies/loara"
            aria-label="Learn more about The Labours of a Rising Adventurer"
          >
            <BorderWrapLinked
              caption={
                <H3>
                  <FakeBtnAlt className="icon" aria-hidden="true">
                    <span className="icon-default">W</span>
                    <BiTrophy title="Trophy Icon" className="icon-mobile" />
                  </FakeBtnAlt>{" "}
                  The Labours of a Rising Adventurer
                </H3>
              }
            >
              <StaticImage
                src="../../images/loara/JulMockup.png"
                alt="A mockup of my in progress RPG. It's presented in grayscale and depicts a variety of enemies, player abilities, and player stats in UI elements that would often be seen in an RPG."
                loading="eager"
              />
            </BorderWrapLinked>
          </LinkPreviewAlt>
        }
        col2={
          <LinkPreviewAlt
            to="/overviews/owgtowg"
            aria-label="Learn more about Open World Game: the Open World Game"
          >
            <BorderWrapLinked
              caption={
                <H3>
                  <FakeBtnAlt className="icon" aria-hidden="true">
                    <span className="icon-default">A</span>
                    <BiCompass className="icon-mobile" title="Compass Icon" />
                  </FakeBtnAlt>{" "}
                  Open World Game: the Open World Game
                </H3>
              }
            >
              <StaticImage
                src="../../images/owgtowg/OWGTOWG_Menu2.jpg"
                alt="A screenshot of Open World Game: the Open World Game's Stats menu. It's primary purpose is to detail the list of pickups, how many pickups have been found by the player, and shiny meters to hype up the player's progress towards one-hundred percent completion."
              />
            </BorderWrapLinked>
          </LinkPreviewAlt>
        }
      />
      <MultiCol
        col1={
          <LinkPreview
            to="/overviews/slime-alchemist"
            aria-label="Learn more about Slime Alchemist"
          >
            <BorderWrapLinked
              caption={
                <H3>
                  <FakeBtn className="icon" aria-hidden="true">
                    <span className="icon-default">S</span>
                    <BiSolidFlask className="icon-mobile" title="Flask Icon" />
                  </FakeBtn>{" "}
                  Slime Alchemist
                </H3>
              }
            >
              <StaticImage
                src="../../images/slime/CraftingMockup.jpg"
                alt="A mock up from Slime Alchemist showing the crafting screen. On the left and bottom side is a list of items, in the center is the main crafting section, and the right side is an expanded information section giving details of one of the cards. The mock up is depicting a 'Paladin card' being prepared to craft by sacrificing one other essence in the center crafting section. Currently a Berserker essence is highlighted showing a card pool of Leap Slap, Furious Rush, And Empowering Strike."
              />
            </BorderWrapLinked>
          </LinkPreview>
        }
        col2={
          <LinkPreview
            to="/overviews/which-is-witch"
            aria-label="Learn more about Which is Witch"
          >
            <BorderWrapLinked
              caption={
                <H3>
                  <FakeBtn className="icon" aria-hidden="true">
                    <span className="icon-default">D</span>
                    <BiSolidCat title="Cat Icon" className="icon-mobile" />
                  </FakeBtn>
                  Which is Witch
                </H3>
              }
            >
              <StaticImage
                src="../../images/wiw/WitchFPScreenshot.jpg"
                alt="A screenshot from Which is Witch depicting the player and various villagers standing around a farm with a large church in the distance. All of this is displayed as if paper cutouts of were standing upright in 3-D space. The UI surrounds the gameplay and currently has the lyrics: to protect us from darkness."
              />
            </BorderWrapLinked>
          </LinkPreview>
        }
      />
    </SectionWrapper>
  )
}
export default FrontPagePreview
